import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const JoinAccuIndex = ({ payment_gateways = [] }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <section className=" paymentGateways">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" style={{ direction: "ltr" }}>
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
              >
                {payment_gateways.map((pg, pgIndex) => (
                  <div
                    key={"ddsdf" + pgIndex}
                    className="gateway"
                    style={{
                      backgroundImage: `url(${pg.gateway_thumbnail.url})`,
                    }}
                  ></div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
